// Copyright © 2020 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.tile-row
  align-items: stretch

.tile-column
  margin-bottom: $ls.xxs

.webhook-tile
  height: 100%
  box-sizing: border-box
  text-decoration: initial
  color: $tc-deep-gray
  text-align: center
  display: block
  overflow: hidden
  text-overflow: ellipsis
  padding: 2rem
  cursor: pointer
  transform: scale(1.0001) // Will fix jiggle in animation.
  background: white
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, .08), inset 0 -1px 0 0 rgba(0, 0, 0, .11)
  border-radius: $br.l
  transition: box-shadow .4s cubic-bezier(.250, .010, .070, 1), transform .4s cubic-bezier(.250, .010, .070, 1)
  +media-query($bp.s)
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, .14), inset 0 -1px 0 0 rgba(0, 0, 0, .11)
    padding: 1.5rem .8rem
  +media-query($bp.m)
    padding: 2rem 2rem

  &:hover
    transform: scale(1.05)
    box-shadow: 0 4px 55px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 0 rgba(0, 0, 0, .11)

  .name
    display: block
    font-size: $fs.l
    font-weight: $fw.bold
    line-height: 1.2
    margin-bottom: $cs.xs
    +media-query($bp.s)
      font-size: $fs.m

  .description
    display: block
    line-height: 1.3
    overflow: hidden
    text-overflow: ellipsis

  .logo
    box-sizing: border-box
    padding: 0 1rem
    width: 100%
    height: 10rem
    object-fit: contain
    margin-bottom: $cs.m
    +media-query($bp.s)
      padding: 0
      height: 7rem
