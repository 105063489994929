// Copyright © 2021 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

:global {
  .tippy-box {
    border-normal()
    z-index: $zi.tooltip
    border-radius: $br.m
    background: white
    padding: $cs.m
    max-width: $bp.xs3
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, .08), inset 0 -1px 0 0 rgba(0, 0, 0, .11)
    transition: opacity, visibility
    white-space: normal

    &[data-state="hidden"] {
      opacity: 0
    }
    &[data-state="visible"] {
      opacity: 1
    }
  }


  .tippy-arrow,
  .tippy-arrow:before  {
    position: absolute
    width: 8px
    height: 8px
    background: inherit
  }

  .tippy-arrow {
    visibility: hidden
  }

  .tippy-arrow:before {
    visibility: visible
    content: ''
    transform: rotate(45deg)
  }

  .tippy-box[data-placement^='top'] > .tippy-arrow {
    bottom: -3px
  }

  .tippy-box[data-placement^='top'] > .tippy-arrow:before {
    border-normal('right')
    border-normal('bottom')
  }

  .tippy-box[data-placement^='bottom'] > .tippy-arrow {
    top: -5px
  }

  .tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
    border-normal('left')
    border-normal('top')
  }

  .tippy-box[data-placement^='left'] > .tippy-arrow {
    right: -3px
  }

  .tippy-box[data-placement^='left'] > .tippy-arrow:before {
    border-normal('right')
    border-normal('top')
  }

  .tippy-box[data-placement^='right'] > .tippy-arrow {
    left: -5px
  }

  .tippy-box[data-placement^='right'] > .tippy-arrow:before {
    border-normal('left')
    border-normal('bottom')
  }
}
